import { Button } from "@/components/ui/button";
import { useAccount } from "@/hooks/use-account";
import { useTranslations } from "@/i18n";
import type { Lang } from "@/types";

export const SignupLink = ({ lang }: { lang: Lang }) => {
  const { ready, account } = useAccount();
  const t = useTranslations(lang);

  if (!ready || account) {
    return null;
  }

  return (
    <Button variant="two" size="sm" asChild>
      <a href={`/${lang}/signup`}>{t("auth.signUp")}</a>
    </Button>
  );
};
